import {  createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface PacketDataSlice {
  value: any[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: PacketDataSlice = {
  value: [],
  status: 'idle',
};

export const packetDataSlice = createSlice({
  name: 'packetData',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clear: (state) => {
      state.value = [];
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    add: (state, action: PayloadAction<any[]>) => {
      state.value = [...state.value, action.payload];
    },
    load: (state, action: PayloadAction<any[]>) => {
      state.value = [...action.payload]
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.

});

export const {clear, add, load} = packetDataSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectData = (state: RootState) => state.packetData.value;


export default packetDataSlice.reducer;
