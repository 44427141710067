import {  createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface ProjectIdSlice {
  value: string | null;
  status: 'null' | 'loading' | 'active';
}

const initialState: ProjectIdSlice = {
  value: null,
  status: 'null',
};

export const projectIdSlice = createSlice({
  name: 'projectId',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clear: (state) => {
      state.value = null;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    set: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.

});

export const {clear, set } = projectIdSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectProjectId = (state: RootState) => state.projectId.value;


export default projectIdSlice.reducer;
