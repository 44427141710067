import blank from '../experiments/Blank.logo';
import calibration from '../experiments/Calibration.logo';
import leds from '../experiments/LEDs.logo';
import LightAndEnergy from '../experiments/LightAndEnergy.logo';
import MFC from '../experiments/MFC.logo';
import packets from '../experiments/Packets.logo';
import sensors from '../experiments/Sensors.logo';
import SolarIV from '../experiments/SolarIV.logo';
import temperature from '../experiments/Temperature.logo';
import turtleLogo from '../experiments/TurtleLogo.logo';
import waterSoil from '../experiments/WaterSoil.logo';
import Solar from '../experiments/Solar.logo';

//Previous experiment imports
//import evaporation from '../experiments/Evaporation.logo';
//import SolarVIP from '../experiments/SolarVIP.logo';
//import plottingDemo from '../experiments/PlottingDemo.logo';


export const experimentsList = [
    {
        name: "Blank",
        code: blank,
        view: 'main'
    },

    {
        name: "Calibration",
        code: calibration,
        view: 'graph'
    },

    {
        name: "LEDs",
        code: leds,
    },

    {
       name: "Sensors",
       code: sensors,
    },
     {
        name: "TurtleLogo",
        code: turtleLogo,
        view: 'main'
    },

    {
        name: "Packets",
        code: packets,
        view: 'data'
    },

    {
        name: "Temperatures",
        code: temperature,
        view: 'graph'
    },

    {
        name: "Water Soil",
        code: waterSoil,
        view: 'graph'
    },

    {
        name: 'Fuel Cell',
        code: MFC,
        view: 'graph'
    },

    {
        name: 'Light and Energy',
        code: LightAndEnergy,
        view: 'graph'
    },
    // {
    //     name: 'Solar IV Curve',
    //     code: SolarIV,
    //     view: 'graph'
    // },
    {
        name: 'Solar Cell',
        code: Solar,
        view: 'graph'
    }
];